import React from "react";
import { Link, NavLink } from "react-router-dom";

export const Home = () => {
  return <div class="container-left-part">
    <div class="left-text">
      <p>Yo, this is my <strong>meaning</strong>:</p>
      <p>I wanna create smth useful for people in human/social/charity/kids/edu(close to these ones) domain. Share ur minds, points, pain.</p>
      <p>Layers of atmosphere reflect stages of concept.</p>
      <br></br>
      <p><strong>Progress</strong>:</p>
      <p><NavLink to="/exosphere">Exosphere</NavLink> stage... layer of the primary research, where it's not so clear what's happening on the land... Current target of this step - accumulate ur ideas, summarize information, decide and start work on exact direction. I'm gonna also share results of this step with you.</p>
      <p>Let's go!</p>
    </div>
    <div className="middle-part-image">
      <img src="images/blue-arrow.png" alt="blue-arrow" />
    </div>
    <div className="right-part-image">
      <img src="images/atmosphere.jpeg" alt="stages" />
    </div>
  </div>
};
