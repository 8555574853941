import React from "react";
import { Link, NavLink } from "react-router-dom";

export const Contact = () => {
  return <div class="container-with-effect">
    <div class="text-content">
      <p>Now u can connect with by:</p>
      <NavLink to="/exosphere">Exosphere</NavLink> or email <a href="mailto:nmw.input@gmail.com">nmw.input@gmail.com</a>
    </div>
  </div>
};
