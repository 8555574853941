import { Route, Routes } from "react-router-dom";
import { Navbar } from "./components/Navbar";
import { About, Contact, Home, Exosphere } from "./components/pages";
import "./App.css";


function App() {
  return (
    <div className="App">
    <Navbar />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/exosphere" element={<Exosphere />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
    </div>
  );
}

export default App;
